import React, { useEffect, useState } from 'react'
import Section from '../../component/admin/Section'
import { Col, Form, Row } from 'react-bootstrap'
import Api from '../../api/Api'
import { toast } from 'react-toastify';
import moment from 'moment'
import { useParams } from "react-router-dom";
import CustomPagination from '../../component/admin/CustomPagination';
const TicketEdit = () =>{
    const {userId} = useParams();
    const [cp , setCp] = useState(0);
    const [ticket , setTicket] = useState(0);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [count, setCount] = useState(0);
    const getUsersDetails = async () =>{
        Api.getUserDetails(userId).then((resData)=>{
            console.log(resData);
          
        })
    }
    const walletHistories = () =>{
    Api.walletHistories(userId, page, limit).then((resData)=>{
        
        setData(resData.result)
        setCount(resData.docCount)
    })
    }
    useEffect(()=>{
        walletHistories();
        getUsersDetails();
    },[userId]);
    useEffect(()=>{
        walletHistories();
       
    },[userId, page]);
    const updateWallets = async () =>{
        toast.promise(Api.updateWallet(userId, {cp: cp, ticket: ticket}).then((result)=>{
                console.log('Task Create -- ',result) 
                return result;
            }),
            {
            pending: 'Chaning...',
                success: 'User Community Point and Tickets Update Successfully!. 👌',
                error: 'rejected 🤯'
        });
        walletHistories();
          setCp(0);
          setTicket(0);
    }
    return(<>
        <Row className="mb-3">
        <Col className='content' lg={12} >
            <Section title={'User Wallet'} buttonText={'Update'} addButton={true} handleAdd={()=>updateWallets()} >
                <Row className="mb-3">
                    <Form.Group className="mb-3" as={Col}>
                        <Form.Label >Community Point:</Form.Label>
                        <Form.Control type="number" value={cp} onChange={(e)=>setCp(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" as={Col}>
                        <Form.Label >Tickets:</Form.Label>
                        <Form.Control type="number"  value={ticket} onChange={(e)=>setTicket(e.target.value)} />
                    </Form.Group>
                </Row>
            </Section>
        </Col>
        <Col className='content' lg={12} >
        <Section title={'Update History'}  >
        <table className="table">
                <thead>
                <tr className='th'>
                    <th className='head' scope="col">#</th>
                    <th className='head' scope="col">cp</th>
                    <th className='head' scope="col">tickets</th>
                    <th className='head' scope="col">Created At</th>
                    {/* <th className='head' scope="col">Action</th> */}
                </tr>
                </thead>
                <tbody>
                    {
                        data && data.map( (item, index) => 
                        <tr key={index}>
                          
                            <th scope="row">{(index+1)+(page * limit) - limit}</th>
                            <td><span style={{color : item.cp < 0 ? '#e60808' : '#2ed107'}}>{item.cp}</span></td>
                            <td><span style={{color : item.tickets < 0 ? '#e60808' : '#2ed107'}} >{item.tickets}</span></td>
                            {/* <td><Image src={item.profile_image} roundedCircle /></td> */}
                            <td>{ moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss') }</td>
                            {/* <td><Button  onClick={()=>handleView(item._id)} variant='info' className='btn-darkblue me-2'><MdOutlineRemoveRedEye /></Button></td> */}
                        </tr>
                        )
                    }
            
                </tbody>
            </table>
            <CustomPagination page={page} counts={count} limit={limit} setPage={(value)=> setPage(value)} />
        </Section>
        </Col>
        </Row>
    </>);
}
export default TicketEdit;