import React, { useState } from 'react'
import Section from '../../component/admin/Section'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { isEmpty } from '../../helper/Helper';
import Err from '../../component/Err';
import Api from '../../api/Api';
import { toast } from 'react-toastify';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import moment from 'moment';
const EventAdd = () => {
  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [reward, setReward] = useState('');
  const [err, setErr] = useState('');
  const [starDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  

  const handleSubmit = async() => {

   
    if(isEmpty(name)) return setErr('Name is required!');
    if(isEmpty(reward)) return setErr('Reward Amount is required!');
    if(isEmpty(type)) return setErr('Reward Type is required!');
    if(isEmpty(starDate)) return setErr('Starting Time is required!');
    if(isEmpty(endDate)) return setErr('Ending Time is required!');
    
   
    const first = moment(starDate);
    const second = moment(endDate);
    console.log({starDate});
    let minutes = second.diff(first, 'minutes');
    setErr('')
    toast.promise(
        Api.createEvent({name : name, reward : reward, reward_type : type, starting_at : starDate, ending_at: endDate, duration : minutes, total_ticket : 0}).then((result)=>{
          console.log('Task Create -- ',result) 
          return result;
        }),
        {
          pending: 'Creating...',
          success: 'Event Created 👌',
          error: 'Event rejected 🤯'
        }
    )
  }

  return (
    <>
    <div className='content'>
        <Section title={'Add Event'} addButton={false}>
        <Err text={err} />
        <Row>
          <Col lg={8}>
            <Row>
                <Col>
                  <Form.Group className="mb-6">
                    <Form.Label >Name :</Form.Label>
                    <Form.Control type="text" value={name} onChange={(e)=>setName(e.target.value)}  />
                  </Form.Group>
                </Col>
               
              </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3">
                    <Form.Label >Reward :</Form.Label>
                    <Form.Control type="number" min={1} value={reward} onChange={(e)=>setReward(e.target.value)}  />
                    </Form.Group>
                </Col>
              <Col>
                <Form.Group className="mb-3">
                <Form.Label >Reward Type :</Form.Label>
                <Form.Control type="text"  value={type} onChange={(e)=>setType(e.target.value)} />
                </Form.Group>
              </Col>
              
            </Row>
            <Row>
               
              <Col>
                <Form.Group className="mb-3">
                <Form.Label >Starting At :</Form.Label>
                <DateTimePicker className="form-control" onChange={setStartDate} value={starDate} format="y-MM-dd H:mm:ss" />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                <Form.Label >Ending At :</Form.Label>
                <DateTimePicker className="form-control" minDate={starDate} onChange={setEndDate} value={endDate} format="y-MM-dd H:mm:ss" />
                </Form.Group>
              </Col>
            </Row>
           
        
            <Button variant='info' onClick={()=>handleSubmit()}>Submit</Button>
          </Col>
        </Row>
         
        </Section>
    </div>
    </>
  )
}

export default EventAdd;