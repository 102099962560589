import React from 'react'
import HeaderAdmin from './HeaderAdmin'
import { Outlet, Route, Routes, useLocation } from 'react-router-dom'
import AdminHome from '../pages/admin/Home'
import Task from '../pages/admin/Task'
import TaskAdd from '../pages/admin/TaskAdd'
import Login from '../pages/Login'
import Settings from '../pages/admin/Settings'
import User from "../pages/admin/User";
import UserData from '../pages/admin/UserData'
import TicketEdit from '../pages/admin/TicketEdit'
import Event from '../pages/admin/Event';
import EventAdd from '../pages/admin/EventAdd';
import EventEdit from '../pages/admin/EventEdit'
import EventJointList from '../pages/admin/EventJointList'
const AdminLayout = ({children}) => {
  const location = useLocation();
  const noHeaderPagesArr = ['/admin/login','/admin/otp']


  if(noHeaderPagesArr.includes(location.pathname)){
    return (
      <Routes>
          <Route path='/login' element={<Login />} />
      </Routes>
    )
  }else{
    return (
      <div className="App">
          <HeaderAdmin /> 
          <Routes>
              {/* <Route path='/' element={<AdminHome />} /> */}
              <Route path='/task' element={<Task />} />
              <Route path='/task/add' element={<TaskAdd />} />
              <Route path='/settings' element={<Settings />} />
              <Route path='/user' element={<User />} />
              <Route path='/user/:userId' element={<UserData />} />
              <Route path='/user/wallet/:userId' element={<TicketEdit />} />
              <Route path='/event' element={<Event />} />
              <Route path='/event/add' element={<EventAdd />} />
              <Route path='/event/:eventId' element={<EventEdit />} />
              <Route path='/event/joinlist/:eventId' element={<EventJointList />} />
          </Routes>
      </div>
    )
  }
  
}

export default AdminLayout