import React, { useState, useEffect } from 'react'
import Api from '../api/Api';

const TaskButton = ({task, status}) => {
    const [buttonText, setButtonText] = useState('GO');
    const [isDisable, setIsDisable] = useState(false)
    const [active, setActive] = useState(status)
    useEffect(()=>{
        setIsDisable(status ? false : true);
        setButtonText(status ? 'GO' : 'Completed');
    },[status]);
    const handleTask = (task) => {
        setIsDisable(true);
        setButtonText('Running...');

        switch(task.type){
            case 'TWEET': 
                Api.createTweet(task.text, task._id).then( (resData)=>{
                    Api.getWallet();
                    console.log('Create__',resData);
                    setButtonText('Completed');
                })
                return;
            case 'LIKE': 
                Api.likeTweet(task.tweetId, task._id).then( (resData)=>{
                    Api.getWallet();
                    
                    console.log('Like__',resData);
                    setButtonText('Completed');
                })
                return;
            case 'COMMENT':
                Api.replyTweet(task.text, task.tweetId, task._id).then((resData)=>{
                    Api.getWallet();
                    console.log('Reply__',resData);
                    setButtonText('Completed');
                })
                return;
            case 'REPOST':
                Api.repostTweet(task.tweetId, task._id).then((resData)=>{
                    Api.getWallet();
                    console.log('Repost__',resData);
                    setButtonText('Completed');
                })
                return;
            default :
                setIsDisable(false);
                setButtonText('GO');
                return
        }
    }
  return (
    <div className="px-5 px-5-device">
        <button className="bg-web text-white font-bold rounded-lg p-3 px-5" onClick={()=>handleTask(task)} disabled={isDisable}>{buttonText}</button>
    </div>
  )
}

export default TaskButton