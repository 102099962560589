import Cookies from 'js-cookie'

export const isEmpty = (variable) => {
    if (variable == null) return true; // Check for null or undefined
    if (typeof variable === 'string' && variable.trim() === '') return true; // Check for empty string
    if (typeof variable === 'number' && variable  === 0) return true; // Check for empty number
    if (Array.isArray(variable) && variable.length === 0) return true; // Check for empty array
    if (typeof variable === 'object' && variable.constructor === Object && Object.keys(variable).length === 0) return true; // Check for empty object
    return false;
  };

// Function to delete all cookies
export const deleteAllCookies = () => {
  Cookies.remove('session')
  Cookies.remove('session.sig')

  window.location.reload();
}