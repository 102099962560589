import React, { useEffect, useState } from 'react'
import Header from '../../layout/Header'
import moment from 'moment'

import { Button, Col, Row } from 'react-bootstrap'
import { useNavigate, useParams } from "react-router-dom";
import Section from '../../component/admin/Section';
import Api from '../../api/Api';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { IoTrashBinOutline } from "react-icons/io5";
import ChangeStatus from '../../component/admin/ChangeStatus';
import CustomPagination from '../../component/admin/CustomPagination';


const Task = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0);
 
  const handleAdd = () => {
    navigate('/admin/task/add')
  }

  const allTask = () =>{
    Api.getAllTask(page, limit).then((resData)=>{
     
      setData(resData.result)
      setCount(resData.docCount)
    })
  }

  const handleView = () =>{

  }

  const handleStatus = () => {

  }

  useEffect(()=>{
    allTask();
  },[page])

  return (
    <Col className='content' lg={10} >
      
          <Section title='Tasks' addButton={true} buttonText={'Add Task'} handleAdd={()=> handleAdd()}>
          <table className="table">
                <thead>
                <tr className='th'>
                    <th className='head' scope="col">#</th>
                    <th className='head' scope="col">Type</th>
                    <th className='head' scope="col">Action Type</th>
                    <th className='head' scope="col">CP</th>
                    <th className='head' scope="col">Tickets</th>
                    <th className='head' scope="col">Tweet Id</th>
                    <th className='head' scope="col">Created At</th>
                    <th className='head' scope="col"></th>
                    <th className='head' scope="col">Status</th>
                    {/* <th className='head' scope="col">Action</th> */}
                </tr>
                </thead>
                <tbody>
                    {
                        data && data.map( (item, index) => 
                        <tr key={index}>
                          
                            <th scope="row">{(index+1)+(page * limit) - limit}</th>
                            <td>{item.type} </td>
                            <td>{item.actionType} </td>
                            <td>{item.cp}</td>
                            <td>{item.tickets}</td>
                            <td>{item.tweetId}</td>
                            <td>{ moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss') }</td>
                            <td><ChangeStatus currentStatus={item.status} id={item._id}/></td>
                            <td>{item.status ? 'Running' : 'Stoped'}</td>
                            {/* <td> 
                                <Button  onClick={()=>handleView(item._id)} variant='info' className='btn-darkblue me-2'><MdOutlineRemoveRedEye /></Button>
                                <Button variant="danger" onClick={()=>handleStatus(item._id)}><IoTrashBinOutline /></Button> 
                             </td> */}
                        </tr>
                        )
                    }
            
                </tbody>
            </table>
            <CustomPagination page={page} counts={count} limit={limit} setPage={(value)=> setPage(value)} />
          </Section>
          <br />
          

   
    </Col>
  )
}

export default Task