import React from 'react'

// Images
import Img_frame1 from '../../assets/public/frame1.png';
import Img_frame2 from '../../assets/public/frame2.png';
import Img_frame3 from '../../assets/public/frame3.png';
import Img_assets1 from '../../assets/public/Section_1/assset1.png';
import Img_assets2 from '../../assets/public/Section_1/assset2.png';
import Img_assets3 from '../../assets/public/Section_1/assset3.png';
import Img_assets4 from '../../assets/public/Section_1/Asset4.png';
import Img_assets5 from '../../assets/public/Section_1/assset5.png';
import Img_assets6 from '../../assets/public/Section_1/assset6.png';
import Img_assets7 from '../../assets/public/Section_1/assset7.png';
import Img_location from '../../assets/public/Section_1/icon/location.png';
import Img_basil_comment from '../../assets/public/Section_1/icon/basil_comment.png';
import Img_retweet from '../../assets/public/Section_1/icon/retweet.png';
import Img_heart from '../../assets/public/Section_1/icon/heart.png';
import Img_twitter from '../../assets/public/Section_1/icon/prime_twitter.png';
import Img_tiktok from '../../assets/public/Section_1/icon/ic_baseline-tiktok.png';
import Img_telegram from '../../assets/public/Section_1/icon/ic_baseline-telegram.png';
import Img_instagram from '../../assets/public/Section_1/icon/iconoir_instagram.png';
import Img_discord from '../../assets/public/Section_1/icon/ic_baseline-discord.png';
import Img_Icons from '../../assets/public/Frame.png'
import Frame1 from '../../assets/public/Rectangle1.png'
import Img_Frame12 from '../../assets/public/Frame12.png'
import TaskList from '../TaskList';
import { useSelector } from 'react-redux';

const Community = () => {
    const authData = useSelector( (state) =>  state.auth );

  return (
    <>
    <div className="contents-data transition-all">
        <div className="py-5 px-5 space-y-10">
            <div className="relative flex justify-center items-center">
                <img src={Img_frame1} alt="" srcset="" />
                <div className="absolute text-white text-2xl md:text-4xl lg:text-7xl">
                    Total Community Points {authData?.wallet?.cp }
                </div>
            </div>
            <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3">
                <div className="flex-col justify-center items-center p-5 border border-white text-center text-white rounded-2xl">
                    <h2 className="font-bold md:text-xl">Referral</h2>
                    <h1 className="text-gray-50/50 text-nowrap md:text-2xl">0</h1>
                </div>
                <div className="flex-col justify-center items-center p-5 border border-white text-center text-white rounded-2xl">
                    <h2 className="font-bold md:text-xl">Engagement</h2>
                    <h1 className="text-gray-50/50 text-nowrap md:text-2xl">{authData?.wallet?.cp }</h1>
                </div>
                <div className="md:col-span-2 lg:col-span-1 flex-col justify-center items-center p-5 border border-white text-center text-white rounded-2xl">
                    <h2 className="font-bold md:text-xl">Bonus</h2>
                    <h1 className="text-gray-50/50 text-nowrap md:text-2xl">0%</h1>
                </div>

            </div>

            <div className="grid grid-cols-2 gap-5 lg:gap-3 lg:grid-cols-4">
                <div className="flex justify-center items-center sm:w-56 sm:h-56 md:w-auto md:h-auto">
                    <img src={Img_assets1} alt="assset1" /></div>
                <div className="flex justify-center items-center sm:w-56 sm:h-56 md:w-auto md:h-auto">
                    <img src={Img_assets2} alt="assset2" /></div>
                <div className="flex justify-center items-center sm:w-56 sm:h-56 md:w-auto md:h-auto">
                    <img src={Img_assets3} alt="assset3" /></div>
                <div className="flex justify-center items-center sm:w-56 sm:h-56 md:w-auto md:h-auto">
                    <img src={Img_assets4} alt="assset4" /></div>
            </div>

            <div className="relative flex justify-center items-center text-white">
                <img src={Img_frame2} alt="" srcset="" />
                <h4 className="absolute lg:text-5xl top-0 md:top-5 left-5 pt-c">Engage On Twitter</h4>
                <div className="absolute py-cust md:p-0">
                    <div className="grid gap-3 lg:gap-16 grid-cols-4 px-5 pt-3 md:pt-0">
                        <div className="md:p-3 md:px-16 p-2 px-5 border border-white rounded-2xl text-center flex flex-col justify-center items-center space-y-4">
                            <div className="flex justify-center items-center">
                                <img src={Img_location} alt="Tag" />
                            </div>
                            <h4 className="text-nowrap text-xs md:text-base">Tag #AST</h4>
                        </div>
                        <div className="md:p-3 md:px-16 p-2 px-5 border border-white rounded-2xl text-center flex flex-col justify-center items-center space-y-4">
                            <div className="flex justify-center items-center">
                                <img src={Img_basil_comment} alt="Comment" />
                            </div>
                            <h4 className="text-nowrap text-xs md:text-base">Comment</h4>
                        </div>
                        <div className="md:p-3 md:px-16 p-2 px-5 border border-white rounded-2xl text-center flex flex-col justify-center items-center space-y-4">
                            <div className="flex justify-center items-center">
                                <img src={Img_retweet} alt="Retweet" />
                            </div>
                            <h4 className="text-nowrap text-xs md:text-base">Retweet</h4>
                        </div>
                        <div className="md:p-3 md:px-16 p-2 px-5 border border-white rounded-2xl text-center flex flex-col justify-center items-center space-y-4">
                            <div className="flex justify-center items-center">
                                <img src={Img_heart} alt="Like" />
                            </div>
                            <h4 className="text-nowrap text-xs md:text-base">Like</h4>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-5">
                <p className='text-home'>Stay active in the Astroon Community and earn rewards!</p>
                {/* <input type="text" name="" id="" placeholder="TEXT HERE" className="w-full p-10 border border-white rounded-lg bg-transparent placeholder:text-white focus:outline-none text-xl text-white" /> */}
            </div>
            <div className="grid grid-cols-3 gap-x-5">
                <div className="flex items-end"><img src={Img_assets5} alt="" /></div>
                <div className="flex items-center"><img src={Img_assets6} alt="" /></div>
                <div className="flex items-start"><img src={Img_assets7} alt="" /></div>
            </div>
           <TaskList />
            <div>
                    <div className="relative flex justify-center items-center">
                        <img src={Img_frame3} alt="frame3" />
                        <div className="absolute grid grid-cols-5 gap-5 md:gap-10" style={{padding:'10px'}}>
                            <div><a  target="_blank" rel="noreferrer" href="https://twitter.com/PlayAstroon" ><img src={Img_twitter} className="h-10 md:h-auto" width={85} alt="Twitter" /></a></div>
                            <div><a  target="_blank" rel="noreferrer" href="https://www.tiktok.com/@playastroon" ><img src={Img_tiktok} className="h-10 md:h-auto" alt="Tiktok" /></a></div>
                            <div><a  target="_blank" rel="noreferrer" href="https://t.me/AstroonChat" ><img src={Img_telegram} className="h-10 md:h-auto" alt="Telegram" /></a>
                            </div>
                            <div><a  target="_blank" rel="noreferrer" href="https://www.instagram.com/playastroon" ><img src={Img_instagram} className="h-10 md:h-auto" alt="Instagram" /></a>
                            </div>
                            <div><a  target="_blank" rel="noreferrer" href="https://discord.com/invite/wakergames" ><img src={Img_discord} className="h-10 md:h-auto" alt="Discord" /></a>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
    
    </>
  )
}

export default Community