import React from 'react'
import './Err.css'
import { isEmpty } from '../helper/Helper'

const Err = ({text}) => {
    if(!isEmpty(text)){
        return (
            <div className='error'>{text}</div>
        )
    }else{
        return ('');
    }
  
}

export default Err