import React, {useEffect, useState} from 'react'
import Logo from '../assets/public/LOGO.png'
import { MdSpaceDashboard, MdManageAccounts } from "react-icons/md";

import Api from '../api/Api'
import { useSelector, useDispatch } from 'react-redux';
import { setAuth } from '../store/authSlice';
import { Link } from 'react-router-dom';
import { useToast } from 'react-toastify';

const Header = () => {

  const authData = useSelector( (state) =>  state.auth );
  const dispatch = useDispatch();
  const [todayTasks, setTodayTasks] = useState([]);
  const [mobile, setMobile] = useState(false);
  const authUser = async() => {
    Api.login().then((resData)=>{
        console.log(resData)
        // console.log("Failed to authenticate user")
      dispatch(setAuth(resData));
    }).catch( error => {

      console.log("Failed to authenticate user")
      dispatch(setAuth({ authenticated: false, user: Object }));
      window.location.replace('/login')
    })
  }

  const getWallet = async() =>{
    await Api.getWallet()
  }

  useEffect(()=>{
    import('./Public.css')
    authUser();
  },[])

  useEffect(()=>{
    if(!authData.authenticated) return;
    getWallet()
  },[authData.authenticated])
  const openNavigation = () =>{
    
    setMobile( mobile ? false : true) 
  }
  return (
    <>
    <header className="flex justify-between px-5 items-center py-5 header-content w-auto lg:max-w-7xl mx-auto ">
      <div className='contents-data transition-all'>
      <nav className="flex justify-center items-center space-x-10">
        <div id="logo">
        <Link to={'/'} className=""><img src={Logo} alt="logo" /></Link>
        </div>
        {/* <div 
          className="hidden md:flex justify-center items-center space-x-5 text-nowrap text-gray-50/80 cursor-pointer">
          <h4>Farming</h4>
          <h4>Claim Airdrop</h4>
        </div> */}
      </nav>
      </div>
     
      <div id="buttons" className='flex header-btn'>
        { authData.user.username === process.env.REACT_APP_ADMIN_USERNAME? 
          <Link to={'/admin/task'} className="ms-1 md:block hidden">
            <button className=" text-web  px-3 py-1 bg-white font-bold rounded-xl">{ authData.authenticated &&  <>
              Admin
            </>}</button>
            {/* <button className="hidden md:block p-3 bg-white font-bold rounded-xl text-web">Sign In</button> */}
        </Link> : ''
        }
          
          <Link to={'/profile'} className="ms-1 md:block hidden">
              <button className=" text-web  px-3 py-1 bg-white font-bold rounded-xl">@{ authData.authenticated && <>
                {authData.user.username}
              </>}</button>
              {/* <button className="hidden md:block p-3 bg-white font-bold rounded-xl text-web">Sign In</button> */}
          </Link>
        <button onClick={openNavigation} className="block md:hidden p-3 px-4 bg-white font-bold rounded-xl mobile-menu">
            <i className="fa fa-bars"></i>
        </button>
       
      </div>

     {mobile && <div className="topnav">
        <div id="myLinks">
          <div 
            className="mobile">
            
            <Link to={'/profile'} className="ms-1 noHover">
              <button onClick={()=>setMobile(false)} class="text-web sm:block px-3 py-1 bg-white font-bold rounded-xl">@{ authData.authenticated && <>
                {authData.user.username}
              </>}</button>
              {/* <button className="hidden md:block p-3 bg-white font-bold rounded-xl text-web">Sign In</button> */}
          </Link>
          </div>     
        </div>
        </div>}
    </header>
    </>
  )
}

export default Header