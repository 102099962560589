import Api from '../../api/Api';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Moment from 'react-moment';
const PastEvent = () =>{
    const authData = useSelector( (state) =>  state.auth );
    const [pastEvent, setPastEvent]= useState([]);
    const getPastEvents = () =>{
      Api.getJoinPastEvent(authData?.user?._id).then((resData)=>{
        setPastEvent(resData);
  
      })
    }
    useEffect(()=>{
        getPastEvents();
    },[authData]);
    return(<>
        <div >
          <div className="grid grid-cols-3 gap-3 md:gap-y-0 md:grid-cols-5 md:gap-x-5 giveaways-content">
              <div className="space-y-3">
                <div className="flex justify-center items-center">
                  <button 
          className="tab-btn w-full  focus:outline-none p-2 text-nowrap md:p-3 text-white font-bold border border-white rounded-md active-tab">Reward</button>
                </div>
                <div className="border md:text-xl border-white space-y-4 p-3 text-center rounded-lg">
                    {pastEvent?.map((item, index)=>{
                        return(<>
                            <h4 className="text-white space-x-1 md:space-x-2" key={`reward-${index}`}><span>{item?.event?.[0]?.reward}</span><span>{item?.event?.[0]?.reward_type}</span></h4>
                        </>);
                    })}
                </div>
              </div>
              <div className="space-y-3">
                <div className="flex justify-center items-center">
                  <button 
          className="tab-btn w-full focus:outline-none p-2 text-nowrap md:p-3 text-white font-bold border border-white rounded-md active-tab">Total Tickets</button>
                </div>
                <div className="border md:text-xl  border-white space-y-4 p-3 text-center rounded-lg">
                {pastEvent?.map((item, index)=>{
                        return(<>
                            <h4 className="text-white space-x-2" key={`spent-${index}`}><span>{item?.event?.[0]?.total_ticket ? item?.event?.[0]?.total_ticket : 0}</span></h4>
                        </>);
                    })}
                </div>
              </div>
              
              <div className="space-y-3 col-span-2 md:col-span-1">
                <div className="flex justify-center items-center">
                  <button
          className="tab-btn  focus:outline-none p-2 md:w-full  md:p-3 text-white font-bold border border-white rounded-md active-tab duration-btn">Duration Time</button>
                
                </div>
                <div className="border md:text-xl border-white space-y-4 p-3 text-center rounded-lg">
                {pastEvent?.map((item, index)=>{
                        return(<>
                            <h4 className="text-white space-x-2" key={`remain-${index}`}><span><Moment diff={item?.event?.[0]?.starting_at}
            unit="hour" >{item?.event?.[0]?.ending_at}</Moment>H</span></h4>
                        </>);
                    })}
                </div>
              </div>
              <div className="space-y-3">
                <div className="flex justify-center items-center">
                  <button 
          className="tab-btn w-full focus:outline-none p-2 text-nowrap md:p-3 text-white font-bold border border-white rounded-md active-tab">Winner</button>
                
                </div>
                <div className="border md:text-xl border-white space-y-4 p-3 text-center rounded-lg">
                {pastEvent?.map((item, index)=>{
                        return(<>
                            <h4 className="text-white space-x-2" key={`winner-${index}`}><span><p></p>{item?.event?.[0]?.winner ? item?.event?.[0]?.winner: '-'}</span></h4>
                        </>);
                    })}
                </div>
              </div>
              <div className="space-y-3">
                <div className="flex justify-center items-center">
                  <button 
          className="tab-btn w-full focus:outline-none p-2 text-nowrap md:p-3 text-white font-bold border border-white rounded-md active-tab">Ticket Spent</button>
                </div>
                <div className="border md:text-xl border-white space-y-4 p-3 text-center rounded-lg">
                {pastEvent?.map((item, index)=>{
                        return(<>
                            <h4 className="text-white space-x-2" key={`spent-${index}`}><span>{item?.spend_ticket ? item?.spend_ticket : 0}</span></h4>
                        </>);
                    })}
                </div>
              </div>
          </div>
        </div>
    </>);
}
export default PastEvent;