import Api from '../../api/Api';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import SpentTicket from './SpentTicket';

const RunningEvent = () =>{
    const [runningEvent, setRunningEvent]= useState([]);
  const getRuningEvents = () =>{
    Api.runningEvent().then((resData)=>{
      setRunningEvent(resData);

    })
  }
  useEffect(()=>{
    getRuningEvents();
  },[]);
  console.log({runningEvent});
    return(<>
        <div >
          <div className="grid grid-cols-3 gap-3 md:gap-y-0 md:grid-cols-5 md:gap-x-5 giveaways-content">
              <div className="space-y-3">
                <div className="flex justify-center items-center">
                  <button 
          className="tab-btn w-full  focus:outline-none p-2 text-nowrap md:p-3 text-white font-bold border border-white rounded-md active-tab">Reward</button>
                </div>
                <div className="border md:text-xl border-white space-y-4 p-3 text-center rounded-lg">
                    {runningEvent?.map((item, index)=>{
                        return(<>
                            <h4 className="text-white space-x-1 md:space-x-2" key={`reward-${index}`}><span>{item?.reward}</span><span>{item?.reward_type}</span></h4>
                        </>);
                    })}
                    
                    
                </div>
              </div>
              <div className="space-y-3">
                <div className="flex justify-center items-center">
                  <button 
          className="tab-btn w-full focus:outline-none p-2 text-nowrap md:p-3 text-white font-bold border border-white rounded-md active-tab">Total Tickets</button>
                </div>
                <div className="border md:text-xl  border-white space-y-4 p-3 text-center rounded-lg">
                {runningEvent?.map((item, index)=>{
                        return(<>
                            <h4 className="text-white space-x-2" key={`spent-${index}`}><span>{item?.total_ticket ? item?.total_ticket : 0}</span></h4>
                        </>);
                    })}
                   
                </div>
              </div>
              
              <div className="space-y-3 col-span-2 md:col-span-1">
                <div className="flex justify-center items-center">
                  <button
          className="tab-btn  focus:outline-none p-2 md:w-full  md:p-3 text-white font-bold border border-white rounded-md active-tab remain-btn">Remaining Time</button>
                
                </div>
                <div className="border md:text-xl border-white space-y-4 p-3 text-center rounded-lg">
                {runningEvent?.map((item, index)=>{
                        return(<>
                            <h4 className="text-white space-x-2" key={`remain-${index}`}><span><Moment format="H" duration={new Date()}
                    date={item?.ending_at}
            />H</span></h4>
                        </>);
                    })}
                    
                   
                </div>
              </div>
              <div className="space-y-3">
                <div className="flex justify-center items-center">
                  <button 
          className="tab-btn w-full focus:outline-none p-2 text-nowrap md:p-3 text-white font-bold border border-white rounded-md active-tab">Winner</button>
                
                </div>
                <div className="border md:text-xl border-white space-y-4 p-3 text-center rounded-lg">
                {runningEvent?.map((item, index)=>{
                        return(<>
                            <h4 className="text-white space-x-2" key={`winner-${index}`}><span><p></p>{'-'}</span></h4>
                        </>);
                    })}
                    
                </div>
              </div>
              <div className="space-y-3">
                <div className="flex justify-center items-center">
                  <button 
          className="tab-btn w-full focus:outline-none p-2 text-nowrap md:p-3 text-white font-bold border border-white rounded-md active-tab">Ticket Spent</button>
                </div>
                <div className="border md:text-xl border-white space-y-4 p-3 text-center rounded-lg">
                {runningEvent?.map((item, index)=>{
                        return(<>
                            <h4 className="text-white space-x-2 d-flex" key={`add-${index}`}><SpentTicket event={item} /></h4>
                        </>);
                    })}
                    
                </div>
              </div>
          </div>
        </div>
    </>);
}
export default RunningEvent