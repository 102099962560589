import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    authenticated: false,
    user: Object,
    wallet: Object
}
export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuth: (state, action) =>{
            state.authenticated = action.payload.authenticated;
            state.user = action.payload.user;
        },
        setWallet: (state, action) => {
            state.wallet = action.payload;
        }

    }

})

export const { setAuth, setWallet } = authSlice.actions;

export default authSlice.reducer;