
import { Route, Routes} from 'react-router-dom';
import AdminLayout from './layout/AdminLayout';
import PublicLayout from './layout/PublicLayout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
function App() {
  // const location = useLocation();

  // const defaultLight = window.matchMedia('(prefers-color-scheme: light)').matches;
  // console.log(defaultLight)
  // const [theme, setTheme] = useLocalStorage('theme', defaultLight ? 'dark' : 'light');

  // const switchTheme = () =>{
    
  //   const newTheme = theme === 'light' ? 'dark' : 'light';
  //   setTheme(newTheme)
  // }
  // console.log(location.pathname)

  return (
    <>
      <Routes>
        <Route path='/*' element={<PublicLayout />} />
        <Route path='/admin/*' element={<AdminLayout />} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
