import React from 'react'
import './Section.css'
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Col, Row } from 'react-bootstrap';

const Section = ({children, handleAdd, title, addButton, buttonText}) => {
    
  return (
    <>
     
        <div className='adminSection'>
            <div className='sectionHead'>
                <h3>{title}</h3> 
                {addButton && 
                <Button variant="primary" onClick={()=>handleAdd()}>{buttonText? buttonText : 'Add'}</Button>
                
                }
            </div>
            <hr />
            <div className='sectionBody'>
            {children}
            </div>
            
        </div>
    </>
  )
}

export default Section