import React from 'react'
import Header from './Header'
import { Outlet, Route, Routes, useLocation } from 'react-router-dom'
import Home from '../pages/Home'
import Profile from '../pages/Profile'
import Login from '../pages/Login'


const PublicLayout = () => {
  const location = useLocation();
  const noHeaderPagesArr = ['/login','/otp']
  
  
  return (
    <div className='bg-[#6141A6] full-height header-top'>
        { noHeaderPagesArr.includes(location.pathname) ? '' : <Header /> } 
        <Routes>
          <Route path='/' element={<Home />}  />
          <Route path="/profile" element={<Profile />}/> 
          <Route path="/login" element={<Login />}/> 
        </Routes>
    </div>
  )
}

export default PublicLayout