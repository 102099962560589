import React, { useEffect } from 'react'
import LogoVertical from '../yogreet-logo-vertical.png'
//import LogoTrim from '../logo-trim.png'

import LogoTrim from '../assets/public/LOGO.png';

// import 'bootstrap/dist/css/bootstrap.min.css';

// import './Admin.css'

import { MdSpaceDashboard, MdManageAccounts } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom'
import Api from '../api/Api';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../store/authSlice';
import { setAdminMenu } from '../store/menuSlice';
import { Col } from 'react-bootstrap';

const HeaderAdmin = () => {
  const dispatch = useDispatch();
  const menuData = useSelector( (state) => state.menu );
  const navigate = useNavigate();
  // Static Data
  const defaultClass = 'menu-item';
  const activeClass = 'menu-item active';

  const authUser = async() => {
    Api.adminLogin().then((resData)=>{
      dispatch(setAuth(resData));
    }).catch( error => {
      console.log("Failed to authenticate user")
      dispatch(setAuth({ authenticated: false, user: Object }));
      window.location.replace('/login')
    })
  }

  const handleMenu = (menu) => {
    dispatch(setAdminMenu(menu))
    navigate(`/admin/${menu}`);
  }

  const menuClass = (menu) => menu === menuData?.activeAdmin ? activeClass : defaultClass;

  useEffect(()=>{
    import('bootstrap/dist/css/bootstrap.min.css');
    import('./Admin.css');
    authUser();
  },[])

  return (
    <>
      <Col lg={2} className='header'>
            <div className='menu-item logo-item'>
                <img className='logo desk-hide' src={LogoVertical} /> 
                <img className='logo mobile-hide' src={LogoTrim} /> 
            </div>
            {/* <div className='menu-item active'>
              <MdSpaceDashboard />
              <Link to={'/admin/task'} >
              <span className='menu-name ms-2'>Home</span>
              </Link>
            </div> */}
            <div className={ menuClass('task') } onClick={()=>handleMenu('task')} >
              <MdSpaceDashboard />
              <span className='menu-name ms-2' >Task</span>
            </div>
            <div className={ menuClass('user') }  onClick={()=>handleMenu('user')}>
              <MdSpaceDashboard />
              <span className='menu-name ms-2' >User</span>
            </div>
            <div className={ menuClass('event') }  onClick={()=>handleMenu('event')}>
              <MdSpaceDashboard />
              <span className='menu-name ms-2' >Event</span>
            </div>
            <div className={ menuClass('settings') }  onClick={()=>handleMenu('settings')}>
              <MdSpaceDashboard />
              <span className='menu-name ms-2' >Settings</span>
            </div>




            {/* <div className='menu-item active'>
                <Link to={'/'} >
                <MdManageAccounts />
                <span className='menu-name ms-2'>Tasks</span>
                </Link>
            </div> */}

           
      </Col>
      {/* <div className='footer-menu desk-hide'> */}
        {/* <div className='menu-item active'>
          <img src={ChatImg} /> 
          <span className='menu-name'>Chat</span>
        </div> */}
        {/* <div className='menu-item'>
          <img src={HistoryImg} /> 
          <span className='menu-name'>History</span>
        </div>
        <div className='menu-item'>
          <img src={PropertyImg} /> 
          <span className='menu-name'>My Property</span>
        </div>
        <div className='menu-item'>
          <img src={FavoriteImg} /> 
          <span className='menu-name'>Favorite</span>
        </div> */}
      {/* </div> */}
    </>
  )
}

export default HeaderAdmin