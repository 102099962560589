import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { Button, Col } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import Section from '../../component/admin/Section';
import Api from '../../api/Api';
import { MdOutlineRemoveRedEye, MdEdit} from 'react-icons/md';
import ChangeStatus from '../../component/admin/ChangeStatus';
import CustomPagination from '../../component/admin/CustomPagination';
import EventStatusChange from '../../component/admin/EventStatusChange';

const Event = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0);
 
  const handleAdd = () => {
    navigate('/admin/event/add')
  }

  const allTask = () =>{
    Api.getAllEvents(page, limit).then((resData)=>{
     
      setData(resData.result)
      setCount(resData.docCount)
    })
  }

  const handleEdit = (id) =>{
    navigate(`/admin/event/${id}`)
  }

  const handleDetails = (id) =>{
    navigate(`/admin/event/joinlist/${id}`)
  }
  

  useEffect(()=>{
    allTask();
  },[page])

  return (
    <Col className='content' lg={10} >
      
          <Section title='Events' addButton={true} buttonText={'Add Events'} handleAdd={()=> handleAdd()}>
          <table className="table">
                <thead>
                <tr className='th'>
                    <th className='head' scope="col">#</th>
                    <th className='head' scope="col">Name</th>
                    <th className='head' scope="col">Reward</th>
                    <th className='head' scope="col">Type</th>
                    <th className='head' scope="col">Starting At</th>
                    <th className='head' scope="col">End At</th>
                    <th className='head' scope="col">Total Ticket</th>
                    <th className='head' scope="col">Created At</th>
                    <th className='head' scope="col">Status</th>
                    <th className='head' scope="col">Action</th>
                </tr>
                </thead>
                <tbody>
                    {
                        data && data.map( (item, index) => 
                        <tr key={index}>
                          
                            <th scope="row">{(index+1)+(page * limit) - limit}</th>
                            <td>{item.name} </td>
                            <td>{item.reward} </td>
                            <td>{item.reward_type}</td>
                           
                            <td>{ moment(item.starting_at).format('YYYY-MM-DD HH:mm:ss') }</td>
                            <td>{ moment(item.ending_at).format('YYYY-MM-DD HH:mm:ss') }</td>
                            <td>{item.total_ticket}</td>
                            <td>{ moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss') }</td>
                            <td><EventStatusChange currentStatus={item.status} id={item._id}/></td>
                            
                            <td> 
                                <Button  onClick={()=>handleDetails(item._id)} variant='info' className='btn-darkblue me-2'><MdOutlineRemoveRedEye /></Button>
                                <Button  onClick={()=>handleEdit(item._id)} variant='info' className='btn-darkblue me-2'><MdEdit /></Button>
                                
                             </td>
                        </tr>
                        )
                    }
            
                </tbody>
            </table>
            <CustomPagination page={page} counts={count} limit={limit} setPage={(value)=> setPage(value)} />
          </Section>
          <br />
          

   
    </Col>
  )
}

export default Event;