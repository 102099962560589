import { useState } from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import Api from "../../api/Api";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
const SpentTicket = ({event}) =>{
    const authData = useSelector( (state) =>  state.auth );
    const [ticket, setTicket] = useState()
    console.log({authData});
    const joinHandler = async () =>{
       const responce = await Api.reqToJoinEvent({userId : authData?.user?._id, eventId: event?._id, spend_ticket : ticket});
       toast.info(responce)
    }
    return(<>
    <span><FaAngleLeft /></span>
    <input type="text" value={ticket} onChange={(e)=>{setTicket(e?.target?.value)}} className="w-full border-white bg-transparent placeholder:text-white focus:outline-none text-spent text-white" />
   
   <span><FaAngleRight /></span>
    <button className="bg-web text-white font-bold rounded-lg btn-spent px-5" onClick={joinHandler} ><FaCheck /></button>
    
    </>)
}
export default SpentTicket;