import React, { useEffect, useState } from 'react'
import Section from '../../component/admin/Section'
import { Col, Form, Row } from 'react-bootstrap'
import Api from '../../api/Api'
import { toast } from 'react-toastify';

const Settings = () => {

  const [config, setConfig] = useState({});

  // const notify = (text) => toastId.current = toast(text);

  // const dismiss = () =>  toast.dismiss(toastId.current);

  const updateSettings = () => {
    toast.promise(
      Api.updateSettings(config).then((resData)=>{
    
        getSettings()
        return resData
      }),
      {
        pending: 'Updating...',
        success: 'Updated 👌',
        error: 'Update rejected 🤯'
      }
  )
  }

  

  const getSettings = () => {
    Api.getSettings().then((resData)=>{
    
      setConfig(resData)
    })
  }



  useEffect(()=>{
    getSettings()
  },[])

  
  return (
    <>
     <Col className='content' lg={6} >
      <Section title={'Settings'} buttonText={'Update'} addButton={true} handleAdd={()=>updateSettings()}>
          <Form.Label>Referral Reward:</Form.Label>
          <Row className="mb-3">
          
          <Form.Group className="mb-3" as={Col}>
          <Form.Label >Community Point:</Form.Label>
            <Form.Control type="number" value={config?.referralCp ? config?.referralCp : 0} onChange={(e)=> setConfig({...config, referralCp: e.currentTarget.value})} />
          </Form.Group>

          <Form.Group className="mb-3" as={Col}>
          <Form.Label >Tickets:</Form.Label>
            <Form.Control type="number" value={config?.referralTickets? config?.referralTickets : 0} onChange={(e)=> setConfig({...config, referralTickets: e.currentTarget.value}) } />
          </Form.Group>
          </Row>
      </Section>
    </Col>
    </>
  )
}

export default Settings